import { service } from '../CONF.js'


//登陆
export function getApiUrl(weburl) {
    const data = {
        weburl: weburl,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/account/getApiUrl', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//登陆
export function loginByUsername(username, password) {
    const data = {
        username: username,
        password: password
    }
    return new Promise( function(resolve, reject) {
        resolve(service().then(function(result){
            return result.post(
                '/main/account/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//注册
export function register(recommendcode=null, username, password, wt_password, name, phone, qq, email, wx, code_key, code_value) {
    const data = {
        recommendcode,
        username,
        password,
        wt_password,
        name,
        
        phone,
        qq,
        email,
        wx,

        code_key,
        code_value,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/account/register', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//代理注册
export function applyAgent(username, password, name, phone, qq, email, wx) {
    const data = {
        username,
        password,
        name,
        
        phone,
        qq,
        email,
        wx,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/UserInfo/applyAgent', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//安全退出
export function safe_exit(Authorization) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/logout',{
                params: {
                    'Authorization': Authorization,
                },
            })
        }))
    })
}

//会员微信登陆
export function register_wx(formdata) {    
    return new Promise(function(resolve, reject) {
        resolve(service(true).then(function(result) {
            return result({
                method:'post',
                url:'/main/account/wx_login', 
                data:formdata, 
                config:{ 
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            })
        }))
    })
}

//验证码图片
export function getVerityCode(uuid) {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/main/account/getVerityCode',
                {
                    responseType: 'blob',
                    params: {
                        key:uuid,
                    },
                }
            )
        }))
    })
}

//开户协议
export function getAgreement(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                'v1.0/aboutInfos', {
                params: {
                    ...data
                }
            })
        }))
    })
}