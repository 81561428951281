<script>    
    import {safe_exit} from '@/api/login'
    import { bank_list } from "@/api/my";

    export default {
        name:'AccountSetting',
        components: {
            Header: () => import('components/base/Header'),

            'mt-cell':app.Mint.Cell,
            'mt-button':app.Mint.Button,
            'mt-header':app.Mint.Header,
        },
        data() {
            return {
                bankCardNumber:null,
                header: {
                    mainTitle: getWord('settings'),
                },
            }
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                if(hisNum == 1){
                    this.$router.push('/home');
                }else{
                    this.$router.go(-1);
                }
            },
            getout() {
                var that=this;
                function handleExit(){
                    that.$store.commit('RESET_userInfo');
                    localStorage.removeItem('user');
                    wsConnection.close();
                    that.$router.push({ path: '/login' });
                }
                if (this._TEMPLATE==='template-3') {
                    app.Mint.MessageBox.confirm(getWord('exit_confirm')+'?').then(action => {
                        safe_exit(JSON.parse(localStorage.getItem('user')).token).then(result => {
                            handleExit();
                        }).catch(err => {
                            app.Mint.Toast(err);
                            handleExit();
                        });
                    });                
                }else {
                    safe_exit(JSON.parse(localStorage.getItem('user')).token).then(result => {
                        handleExit();
                    }).catch(err => {
                        app.Mint.Toast(err);
                        handleExit();
                    });
                }
            },
        },
        mounted(){
            bank_list().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.bankCardNumber=result.data.result[0] && result.data.result[0].bank_num;
                }
            });
        }
    }
</script>

<template>
    <section id="personal" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <div class="myposition"></div>
            <div class="main">
                <Header
                    :mainTitle="header.mainTitle"
                    :backButton=false
                />
                <div class="main_text">
                    <router-link to="/my/set_up/login_password" style="text-decoration: none">
                        <mt-cell class="main_text_cell" :title="getWord('password7')" is-link>
                            <img slot="icon" src="@@/assets/images2/user/drmm.png" alt="">
                        </mt-cell>
                    </router-link>
                    <router-link to="/my/set_up/presentation_password" style="text-decoration: none">
                        <mt-cell class="main_text_cell" :title="getWord(['withdraw', 'password'])" is-link>
                            <img slot="icon" src="@@/assets/images2/user/txmm.png" alt="">
                        </mt-cell>
                    </router-link>
                </div>
                <mt-button class="main_button" type="danger" @click="getout()">{{ getWord('sign_out') }}</mt-button>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <main>
                <div class="buttons">
                    <router-link to="/my/set_up/login_password">
                        <i class="iconfont icon-lock"></i>
                        {{ getWord(['password7', 'modify']) }}
                        <i class="iconfont icon-back"></i>
                    </router-link>
                    <router-link to="/my/set_up/presentation_password">
                        <i class="iconfont icon-card"></i>
                        {{ getWord(['password8', 'modify']) }}
                        <i class="iconfont icon-back"></i>
                    </router-link>
                    <router-link to="/my/my_bank/add_bank" v-if="!bankCardNumber">
                        <i class="iconfont icon-credit"></i>
                         {{ getWord(['bind', 'bankcard']) }}
                        <i class="iconfont icon-back"></i>
                    </router-link>
                    <router-link to="/my/my_bank/no_bank" v-else>
                        <i class="iconfont icon-credit"></i>
                        {{ getWord(['bankcard', 'management']) }}
                        <i class="iconfont icon-back"></i>
                    </router-link>
                </div>

                <a class="button" @click="getout()">{{ getWord('sign_out') }}</a>
            </main>
        </template>
    </section>
</template>

<style scoped lang='scss' type="text/css">
#personal {

    &.template-3 {
        background-color: #EEEEEE;
        height: 100%;

        main {

            .buttons {
                padding:0 .25rem;
                margin-bottom: .25rem;
                background-color: #ffffff;

                a {
                    color: #5F646E;
                    font-size: .3rem;
                    display: block;
                    border-top: 1px solid #B0B0B0;
                    padding: .25rem .05rem;

                    &:first-child {
                        border-top:0;
                    }

                    .iconfont {
                        font-size: .5rem;
                        vertical-align: middle;
                        margin-right: .1rem;

                        &:first-child {
                            color: #EC2829
                        }
                    }

                    .icon-back {
                        margin-right: 0;
                        font-size: .4rem;
                        opacity: .4;
                        float: right;
                        display: inline-block;
                        transform: rotateZ(180deg);
                    }
                }
            }

            > a {
                border-radius: 3px;
                color: #EC2829;
                font-size: .36rem;
                text-align: center;
                display: block;
                background-color: #ffffff;
                margin: 0 .25rem;
                padding: .175rem;
            }
        }
    }

    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .main_text {
        float: left;
        width: 100%;
        background-color: white;
        margin-top: 0.3rem;
    }

    .main_text img {
        width: 0.42rem;
        height: 0.42rem;
    }

    .main_text_cell {
        border-bottom: 1px solid rgba(187, 187, 187, 1);
    }

    .main_button {
        width: 7.12rem;
        height: 0.88rem;
        margin: 0.6rem 0 0 0.2rem;
    }
}    
</style>


